import React, { Component } from 'react'
import { Box, Button, ChangeCodeManagementModal, Icons, Input, List, Page, Spacer } from 'components'
import { getLocationState, navigate } from 'shared/router'
import { ChangeCodeManagement, CustomTmrItem, GroupedVariantItems, Order } from 'api/types'
import { __, T } from 'translations/i18n'
import CustomItems from 'api/CustomItems'
import { showToast } from 'shared/utils'
import ChangeCodeVariantRow from 'components/ChangeCodeVariantRow'

interface State {
  productionOrder?: Order
  items: CustomTmrItem[]
  filteredItems: CustomTmrItem[]
  loading: boolean
  disableCheckboxes: boolean
  query: string
  isAllChecked: boolean
  madeFor?: string
  taglie: string[]
  colori: string[]
  showModal: boolean
  dto?: ChangeCodeManagement
  /** list of item id checked */
  checkedList: string[]
}

export default class PackingListCreate extends Component<{}, State> {
  state: State = {
    loading: true,
    disableCheckboxes: false,
    query: '',
    isAllChecked: false,
    productionOrder: undefined,
    items: [],
    filteredItems: [],
    taglie: [],
    colori: [],
    showModal: false,
    checkedList: [],
  }

  navigateBack = () => {
    this.resetState()
    navigate('/change-code/management')
  }

  async componentDidMount() {
    const locationState = getLocationState(this.props)
    if (!locationState) {
      navigate('/change-code/management')
    } else {
      this.setState(
        {
          productionOrder: locationState.productionOrder,
          taglie: locationState.taglie,
          colori: locationState.colori,
        },
        this.reloadItems
      )
    }
  }

  async reloadItems() {
    if (this.state.productionOrder?.code) {
      this.setState({ loading: true, showModal: false })
      const items = await CustomItems.getChangeCodeToManage(this.state.productionOrder.code)
      this.setState({ loading: false, items, filteredItems: items, checkedList: [] })
    }
  }

  onInputEnter = (input: string) => {
    this.setState({ query: input })
  }

  groupItems = (items: CustomTmrItem[]): GroupedVariantItems => {
    const groupItems = items.reduce((acc, curr) => {
      const keys: string[] = [
        curr.upc,
        curr.changeStatus,
        curr.product.style?.value,
        curr.product.color?.value,
        curr.product.size?.value,
      ]
      const key = keys.map((k) => `${k}`).join('_')

      if (acc[key] && Array.isArray(acc[key])) {
        acc[key].push(curr)
      } else {
        acc[key] = [curr]
      }
      return acc
    }, {} as GroupedVariantItems)
    return groupItems
  }

  filterResults = (result: CustomTmrItem) => {
    const { query } = this.state
    return result.itemIdentifiers
      .find((idf) => idf.identifierType === 'CertilogoIdentifier')
      ?.code.toString()
      .includes(query)
  }

  filterItems = () => {
    const { items, checkedList } = this.state
    const filteredItems = items.filter(this.filterResults)
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      // remove items checked if not visible
      if (!filteredItems.find((fi) => fi.id === item.id)) {
        const idxCheckItem = checkedList.indexOf(item.id)
        if (idxCheckItem !== -1) {
          checkedList.splice(idxCheckItem, 1)
        }
      }
    }
    this.setState({ filteredItems, checkedList })
  }

  handleChange = (event: any) => {
    const { items, checkedList } = this.state
    let isAllChecked = false
    if (event.target.checked) {
      checkedList.push(event.target.value)
      isAllChecked = checkedList.length === items.length
    } else {
      const idx = checkedList.indexOf(event.target.value)
      checkedList.splice(idx, 1)
    }
    this.setState({ isAllChecked, checkedList })
  }

  handleChangeGroup = (event: any) => {
    const { items, checkedList } = this.state
    let isAllChecked = false
    const checkGroup = event.target.checked
    const idsGroupValue: string = event.target.value
    const idsGroup = idsGroupValue?.split(',')
    if (!Array.isArray(idsGroup)) return
    for (let i = 0; i < idsGroup.length; i++) {
      const id = idsGroup[i]
      const alreadyPresentIdx = checkedList.indexOf(id)
      if (checkGroup) {
        // must add if not available
        if (alreadyPresentIdx === -1) {
          checkedList.push(id)
        }
      } else if (alreadyPresentIdx !== -1) {
        // must remove if available
        checkedList.splice(alreadyPresentIdx, 1)
      }
    }
    isAllChecked = checkedList.length === items.length
    this.setState({ isAllChecked, checkedList })
  }

  handleAllCheck = (checked: boolean, data: string[]) => {
    this.setState({ disableCheckboxes: true })
    const { items, checkedList } = this.state
    if (checked) {
      data.forEach((n) => {
        if (!checkedList.includes(n)) checkedList.push(n)
      })
    } else {
      data.forEach((n) => {
        const i = checkedList.indexOf(n)
        checkedList.splice(i, 1)
      })
    }
    this.setState({
      isAllChecked: checkedList.length === items.length,
      disableCheckboxes: false,
      checkedList,
    })
  }

  onClickConfirm = () => {
    const { checkedList } = this.state
    if (checkedList.length === 0) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.item_not_selected),
        status: 'error',
      })
    } else {
      this.setState({ showModal: true })
    }
  }

  onConfirm = async (color?: string, size?: string) => {
    const { checkedList } = this.state
    try {
      const requestData: ChangeCodeManagement = { itemId: checkedList, color: color, size: size }
      await CustomItems.manageChangeCode(requestData)
      showToast({ title: __(T.misc.success), description: __(T.messages.operation_success), status: 'success' })
    } catch (e) {
      showToast({ title: __(T.error.error), description: (e as any)?.message ?? e, status: 'error' })
    }
    this.reloadItems()
  }

  resetState = async () => {
    this.setState({
      loading: false,
      disableCheckboxes: false,
      query: '',
      isAllChecked: false,
      productionOrder: getLocationState(this.props).productionOrder,
      items: [],
      taglie: [],
      colori: [],
      showModal: false,
      checkedList: [],
    })
  }

  onClose = () => {
    this.setState({ showModal: false })
  }

  render() {
    const {
      loading,
      disableCheckboxes,
      isAllChecked,
      productionOrder,
      items,
      filteredItems,
      taglie,
      colori,
      showModal,
      checkedList,
    } = this.state
    const grouped = this.groupItems(filteredItems)

    return (
      <Page
        loading={loading}
        title={`${__(T.titles.change_code_management) ?? 'Packing List'} / ${__(T.misc.association)}`}
        header={{
          details: [
            {
              label: __(T.misc.production_order),
              value: productionOrder?.code,
            },
          ],
        }}
        onBackPress={() => {
          this.navigateBack()
        }}
      >
        <Page.Content>
          <Page.TopBar>
            <Input
              startFocus
              placeholder={__(T.misc.certilogo)}
              onChange={(query) => this.setState({ query }, this.filterItems)}
              image={<Icons.Barcode />}
              style={{ width: 350 }}
              onEnter={this.onInputEnter}
            />
            <Spacer />
            <Box flex />
            <Button
              loading={loading}
              title={isAllChecked ? __(T.misc.unselect_all) : __(T.misc.select_all)}
              variant="secondary"
              onClick={() =>
                this.handleAllCheck(
                  !isAllChecked,
                  items.map((n) => n!.id!)
                )
              }
            />
            <Spacer />
            <Button loading={loading} title={__(T.confirm.change_items)} onClick={this.onClickConfirm} />
          </Page.TopBar>
          <List
            loading={loading}
            title={`${__(T.titles.change_code)}`}
            renderItem={(i) => (
              <ChangeCodeVariantRow
                disabled={disableCheckboxes}
                items={i}
                checkboxHandleChange={this.handleChange}
                checkboxHandleChangeGroup={this.handleChangeGroup}
                checkboxLabel={__(T.misc.select)}
                checkboxLabelGroup={__(T.misc.select)}
                checkedIds={checkedList}
              />
            )}
            data={Object.values(grouped)}
          />
          <ChangeCodeManagementModal
            title={__(T.misc.manage)}
            onConfirm={this.onConfirm}
            onClose={this.onClose}
            sizes={taglie}
            colors={colori}
            isOpen={showModal}
          />
        </Page.Content>
      </Page>
    )
  }
}
