import React, { Component } from 'react'
import { ProductionOrderCounter } from 'api/types'
import { Box, List } from 'components'
import styled from '@emotion/styled'
import { __, T } from 'translations/i18n'
import ProductionOrderCounterStatus from './ProductionOrderCounterStatus'

interface Props {
  orderCounterRow: ProductionOrderCounter
  onRowClick: (productionOrderCounter: ProductionOrderCounter) => void
}
export default class CustomShipmentParcelRow extends Component<Props> {
  render() {
    const { onRowClick, orderCounterRow } = this.props
    let userDisplay = ''
    if (orderCounterRow.user?.name) {
      userDisplay = `${orderCounterRow.user?.name}`
    }
    if (orderCounterRow.user?.username) {
      userDisplay = `${userDisplay} (${orderCounterRow.user?.username})`
    }

    return (
      <RowItem onClick={() => onRowClick(orderCounterRow)}>
        <Box>
          <List.Cell label={__(T.misc.production_order)} value={orderCounterRow.productionOrder.code} />
        </Box>
        <Box>
          <List.Cell label={__(T.misc.user)} value={userDisplay} />
        </Box>
        <Box>
          <Box style={{ fontWeight: 700, marginBottom: 5 }}>{__(T.misc.status)}</Box>
          <ProductionOrderCounterStatus width={200} status={orderCounterRow.changeStatus} />
        </Box>

        <Box>
          <SmallCounter>{`${orderCounterRow?.quantity > 0 ? `${orderCounterRow?.quantity}` : ''}`}</SmallCounter>
        </Box>
      </RowItem>
    )
  }
}

const RowItem = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px 30px;
  /* border: 2px solid #edecec; */
  min-height: 103px;
  margin-bottom: 26px;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`

const SmallCounter = styled(Box)<{
  detected?: number
  expected?: number
  unexpected?: number
}>`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  padding: 0px 20px;
  background-color: ${({ unexpected = 0, detected = 0, expected = 0 }) => {
    if (unexpected > 0) return '#F2B57D'
    if (expected === 0) return '#EDEDED'
    if (detected < expected) return '#EDEDED'
    if (detected === expected) return '#75EBA8'
    if (detected > expected) return '#F2B57D'
    return '#75EBA8'
  }};
`
