import React, { Component } from 'react'
import { Page, Icons, Box, Form } from 'components'
import RemoteConfig, { InboundConfig } from 'shared/RemoteConfig'
import { getMatchParams, navigate } from 'shared/router'
import InboundShipments from 'api/InboundShipments'
import { showToast } from 'shared/utils'
import { T, __ } from 'translations/i18n'

export default class CustomInbound extends Component {
  operation = RemoteConfig.getOperationConfig<InboundConfig>(getMatchParams(this.props).configCode)

  onSubmit = async (data: { parcelCode: string }) => {
    try {
      const result = await InboundShipments.searchCounters({
        parcelStates: ['IN_TRANSIT', 'RECEIVE_AWAIT', 'IN_INBOUND', 'INBOUND_ERROR', 'READY_TO_RECEIVE'],
        parcelCodes: [data.parcelCode],
        configurationId: this.operation.id,
      })
      if (!result || result.length === 0)
        return showToast({ title: __(T.error.error), description: __(T.error.not_found), status: 'error' })

      if (
        result[0].header.parcelState &&
        result[0].header.parcelState !== 'READY_TO_RECEIVE' &&
        result[0].header.parcelState !== 'IN_TRANSIT' &&
        result[0].header.parcelState !== 'IN_INBOUND'
      ) {
        return showToast({
          title: __(T.error.error),
          description: __(T.error.parcel_already_received),
          status: 'error',
        })
      }

      if (this.operation.isCustomGateInbound === 'yes' && this.operation.inboundMode === 'parcelByParcel') {
        navigate('/inbound/gate/:configCode/reading/:parcelCode', {
          configCode: this.operation.code,
          parcelCode: result[0].header.parcelCode,
        })
        return true
      }

      navigate('/inbound/:configCode/reading/:parcelCode', {
        configCode: this.operation.code,
        parcelCode: result[0].header.parcelCode,
      })
      return true
    } catch (err) {
      showToast({
        title: __(T.error.error),
        description: err?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  render() {
    return (
      <Page title={`${this.operation?.description ?? 'Inbound'}`} onBackPress={() => navigate('/')}>
        <Page.Content bgGrey>
          <Box flex center>
            <Form
              schema={[
                {
                  label: __(T.misc.parcel),
                  name: 'parcelCode',
                  image: <Icons.Barcode />,
                  focus: true,
                },
              ]}
              submitText={__(T.misc.next)}
              onSubmit={this.onSubmit}
              width={400}
            />
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
